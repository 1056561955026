module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jacek Dabkiewicz","short_name":"Jacek Dabkiewicz","start_url":"/","background_color":"#4B5F86","theme_color":"#4B5F86","display":"standalone","icon":"src/images/jd-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WMNV7G5","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
