export const onRouteUpdate = ({ location }) => {
  // Track Page View with GTM
  let pageTitle = location.pathname.replace('/', '')
  pageTitle = pageTitle === '' ? 'home-page' : pageTitle
  // eslint-disable-next-line no-undef
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: 'VIRTUAL_PAGE_VIEW',
      virtualPageURL: location.pathname + location.search + location.hash,
      virtualPageTitle: pageTitle,
    })
  }
}
